import React from 'react';
import styled from 'styled-components';
import MediaLayout from 'layouts/media-layout';
import SEO from 'components/seo';
import { Col33, ArrowToBottom, GoBackButton, MobileHeading } from 'components/misc';

const PHOTOS_VIDEOS = [
  {
    id: 1,
    title: 'Nowa siedziba w Kisielinie',
    date: '09.03.2020',
    name: 'media-headquarters'

  },
  {
    id: 2,
    title: 'Odwiedziny Prezydenta',
    date: '09.03.2020',
    name: 'media-car'
  },
  {
    id: 3,
    title: 'Dostawa Hamburg VHH',
    date: '09.03.2020',
    name: 'media-shipyard'
  },
  {
    id: 4,
    title: 'Nowa siedziba w Kisielinie',
    date: '09.03.2020',
    name: 'media-headquarters-copy'
  },
  {
    id: 5,
    title: 'Odwiedziny Prezydenta',
    date: '09.03.2020',
    name: 'media-car-copy'
  },
  {
    id: 6,
    title: 'Dostawa Hamburg VHH',
    date: '09.03.2020',
    name: 'media-shipyard-copy'
  },
];

const StyledPressInfo = styled.div`
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Title = styled.h3`
  font-size: 1.285rem;
  font-weight: 500;
`;

const PressInfo = ()=> {
  const handelArrowClick = (id)=> ()=> {
    console.log('press-news', id);
  };

  return (
    <MediaLayout>
      <SEO title="Media" />
      <GoBackButton path="media" name="Media" />
      <MobileHeading heading="Informacje prasowe" />
      <Col33>
        {PHOTOS_VIDEOS.map((data)=> {
          return <StyledPressInfo key={data.id}>
            <p>{data.date}</p>
            <Title>{data.title}</Title>
            <ArrowToBottom onClick={handelArrowClick(data.id)} title="Pobierz pdf" />
          </StyledPressInfo>;
        })}
      </Col33>
    </MediaLayout>
  );
};

export default PressInfo;
